<template>
  <v-app style="background-color:#f8f9fb" >

        <!-- Diálogo de progresso -->
  <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a {{this.dias}} dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilterDownload" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Em Manutenção
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilterDownload = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    <v-container style="background-color:#f8f9fb;" >
      <v-col cols="12">
        <filtro-component :opcoes="opcoes" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'mes'"
        @filtrar="filterData" :title_opcao="'Campanha'" :campos="'N'"
          ></filtro-component>
      </v-col>
    </v-container>
    <v-row>


    
      <v-col
      cols="12"
      sm="6"
      lg="3">
      
      <base-material-stats-card 
     color="rgb(71 130 180)"
     icon="mdi-cash"
     title="Recebido"
     sub-icon-color="red"
     :valuex='false'
     :value='formatarMoeda(this.total_pago)'
     :sub-text="'Total Recebido'"
   />
      
     </v-col>
  
  
  
  
    <v-col
     cols="12"
     sm="6"
     lg="3">
     
      <base-material-stats-card 
        color="rgb(71 130 180)"
        icon="mdi-cash"
        title="Comissão de Acordos"
        sub-icon-color="red"
        :valuex='false'
        :value='formatarMoeda(this.total_pago_comissao)'
        :sub-text="'Total Pago com Comissão '"
      />
  
  
    </v-col>
  
  
  
    </v-row>

    <br/>
   
    <v-divider></v-divider>
    
    <br/><br/>


  <v-container style="background-color:#eee"  
    id="financeiro"
    fluid
    tag="section">
   
    
    <v-row>
   
  
    <v-col
    cols="12">

        <base-material-card
        icon="mdi-percent"
        title="Comissão"
        color="rgb(71 130 180)" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="ranking_header"
              :items="lista_comissao_grupo"
              :items-per-page="20" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      
              <br/> <br/> <br/>
  
              <template v-slot:top>
                <v-progress-linear
                  v-if="isLoading" 
                  indeterminate
                  color="rgb(71 130 180)"
                ></v-progress-linear>
              </template>

                        <template v-slot:item="row">
                          
                          <tr :class="{'cor-row': row.index % 2 !== 0}">
                                <td class="linha" style="width: 32%;font-size:11px;font-weight: bold;font-family: Verdana, Geneva, Tahoma, sans-serif">
                                  <v-icon
                                  color="black"
                                  size="24"
                                  class="ml-2 mr-1">

                                  <template v-if="row.item.tipo === 'OPERADOR (A)'">
                                    mdi-headset
                                  </template>
                                  <template v-else-if="row.item.tipo === 'MOTOBOY'">
                                    mdi-motorbike
                                  </template>

                                  <template v-else-if="row.item.tipo === 'MotoGirl'">
                                    mdi-motorbike
                                  </template>

                                  <template v-else-if="row.item.tipo === 'SUPERVISOR (A)'">
                                    mdi-account-supervisor
                                  </template>

                                  <template v-else-if="['Gerência Adjunta', 'Gerência Unidade', 'GERÊNCIA'].some(type => row.item.tipo.includes(type))">
                                      mdi-account-supervisor
                                    </template>



                                  <template v-else-if="row.item.profissao === 'Supervisor (a)'">
                                    mdi-account-supervisor
                                  </template>

                                  


                                </v-icon>
                                 

                                  {{row.item.usuario}}

                                 

                                </td>
                                <td class="linha" style="width: 12%;font-size:14px;"> 
                                  {{row.item.tipo.toUpperCase()}}

                                </td>
                                <td class="linha" style="width:8%;font-size:14px;">
                                  <v-chip 
                                  :color="getStatus(row.item.empresa)"
                                  text-color="white">
                                      {{row.item.empresa}}                    
                                      
                                  </v-chip>  
                                </td>

                                  <td class="linha" style="width: 10%;font-size:14px;"> 
                                
                                    {{
                                      parseFloat(row.item.original).toLocaleString('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                      })
                                    }}      
                                  </td>
           

                                  <td class="linha" style="width: 8%;font-size:14px;">
                              

                                    {{
                                       parseFloat(row.item.pago).toLocaleString('pt-BR', {
                                         style: 'currency',
                                         currency: 'BRL'
                                       })
                                     }}     
                                    
                                  </td>

                                  <td class="linha" style="width: 4%;font-size:14px;">
                                    {{row.item.percentual }}    
                                   </td> 


                                <td class="linha">
                                  {{
                                    parseFloat(row.item.comissao).toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })
                                  }}     
                                </td>
                              
                                

                            
                          </tr>
                      </template>
              </v-data-table>


      <br/>
  
        <div class="d-flex justify-end" >
          <div style="width: 240px;">

            <span @click="download_alelo_xls"  style="color:orange; font-size: 48px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-gift"></i>
            </span>

            <span @click="download_xls"  style="color:green; font-size: 48px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-excel"></i> 
            </span>

            <span @click="exportToPDF"  style="color:red; font-size: 48px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-pdf"></i> 
            </span>
            
            <br/>
          
          </div>
        </div>  


    <br/>

    </base-material-card>
  
    </v-col>

  

  </v-row>


  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto
  import Comissao from '../../services/comissao' 
  import { ContentLoader } from 'vue-content-loader'
  import jsPDF from 'jspdf';
  import 'jspdf-autotable';

  export default {
    name: 'Obitos',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,

    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())

      this.selectedStartDate2 = this.getFirstDayOfMonth(),
      this.selectedEndDate2 =  this.formatDateBR(new Date())
    },


  

    
    mounted() {
     this.carregarLoad(),
     this.dashboard();
     this.handleFilterChange(this.selectMode, this.selectedStartDate, this.selectedEndDate, this.selectedStartDate2, this.selectedEndDate2);
    },
   

    data () {
      return {
        ranking_header: [
          { text: 'Usuário', value: 'usuario' },
          { text: 'Tipo', value: 'tipo' },

          { text: 'Empresa', value: 'empresa' },
          { text: 'Original', value: 'original' },
          { text: 'Recebido', value: 'pago' },
          { text: '%', value: 'percentual' },
          { text: 'Comissão', value: 'comissao' }
        ],


         lista_comissao: [],
         lista_comissao_grupo: [],
         produto: 'Todos',
         bool_03 : true,
         bool_02: true,
         total_resultado: 0,
         total_pago_comissao: 0,
         total_pago: 0,

         total_pago_pre: 0,
         total_pago_par: 0,
         total_pago_mem: 0,
         total_pago_ver: 0,
         total_pago_am: 0,

         subTextAddDynamic: "Total Recebido:",
         recebido_acordo:0,
         qtd_recebido_acordo:0,
         criado_acordo: 0,
         animationInterval: null, // Intervalo para controlar a animação
         empresa: 'Todas',
         selectedCompany: "Todas",
         selectedType: "Todos",
         selectedOpcao: "Todas",
         opcoes: ["Todas","Campanha 50 Anos"],
         selectMode: "Pagamento",
         selectedStartDate : "",
         selectedEndDate : "",
         isLoading: false,
         modos: ["Período"],
        modos2: ["SIM", "NÃO"],
          campos: 'N',
          categoria: [],
          showFilter: false,
          showProgress: false,
          showFilterDownload: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos"],
          tipos_funerarios: [
                "Todos"
              ],

       

           lista_nome_empresa:[],
      
      }
    },

   
    methods: {
     
      
      

        isDateValid(dateString) {
          // Verifica se a string é uma data válida
          const date = new Date(dateString);
          return !isNaN(date.getTime());
        },
        areDatesValid(dates) {
          // Verifica se todas as datas na matriz são válidas
          return dates.every(dateString => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
          });
        },

        handleFilterChange(selectedFilter, dataI, dataF, DataRI, DataRF) {

         

          if (selectedFilter === 'Vencimento') {
            this.subTextAddDynamic = 'Total Recebido: ';
          } else if (selectedFilter != 'Vencimento') {

            if (
              converteDataParaFormatoISO(DataRI) <= converteDataParaFormatoISO(dataI) &&
              converteDataParaFormatoISO(DataRF) > converteDataParaFormatoISO(dataF)
              ) {
                this.subTextAddDynamic = 'Total Recebido com Acréscimo: ';
              }else{
                this.subTextAddDynamic = 'Total Recebido: ';

              }
          }

        },

      filterData(valores) {
        
          this.carregarLoad(),
          this.isLoading = true;
          let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.selectMode = "Pagamento";

          this.handleFilterChange(valores.selectMode, valores.selectedStartDate, valores.selectedEndDate, valores.selectedStartDate2, valores.selectedEndDate2);
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.selectedStartDate2 = valores.selectedStartDate2;
          this.selectedEndDate2 = valores.selectedEndDate2;
          this.selectedStartDate3 = valores.selectedStartDate3;
          this.selectedEndDate3 = valores.selectedEndDate3;
          this.produto = valores.selectedProduto;
          //this.gf01();
          this.dashboard();
         
      },

      exportToPDF() {
    const doc = new jsPDF('landscape', 'pt', 'a4'); // Define a orientação da página como paisagem e tamanho A4
    const usuario = JSON.parse(sessionStorage.getItem('user')).nome;

    const titleY = 30; // Posição inicial para o título
    const periodY = titleY + 18; // Posição para o período, 20 unidades abaixo do título
    const maxLinesPerPage = 18; // Limite de linhas por página

    // Cabeçalhos da tabela
    const headers = this.ranking_header.map(header => header.text);

    // Dados da tabela
    const filteredData = this.lista_comissao_grupo
    .filter(item => parseFloat(item.comissao || 0) > 0) // Filtra itens onde a comissão é maior que 0
    .map(item =>
        this.ranking_header.map(header => {
            let value = item[header.value]; // Define 'value' com o valor do item

            if (header.value === 'original' || header.value === 'pago' || header.value === 'comissao') {
                return parseFloat(value || 0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                });
            } else if (header.value === 'tipo') {
                return value ? value.toUpperCase() : ''; // Aplica toUpperCase() em profissao
            }
            
            return value !== null && value !== undefined ? value : '';
        })
    );

    let startY = titleY + 40; // Posição Y inicial para a tabela
    let pageCount = 0;

    const addTableToPDF = (data, isFirstPage) => {
        doc.autoTable({
            startY: startY,
            head: [headers],
            body: data,
            theme: 'grid',
            headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
            columnStyles: { 0: { cellWidth: 'auto' } },
            margin: { bottom: 20 },
            didDrawPage: (data) => {
                if (isFirstPage) {
                    // Adiciona o título e o período na parte superior da página apenas na primeira página
                    doc.setFontSize(14);
                    doc.setFont('helvetica', 'bold');
                    doc.text('Comissão de Acordos', 20, titleY); // Adiciona o título
                    doc.setFontSize(12);
                    doc.setFont('helvetica', 'normal');
                    doc.text('Período: ' + this.selectedStartDate + ' até ' + this.selectedEndDate, 20, periodY); // Adiciona o período
                }
            }
        });
    };

    // Processa os dados em blocos de até 20 linhas por página
    for (let i = 0; i < filteredData.length; i += maxLinesPerPage) {
        const pageData = filteredData.slice(i, i + maxLinesPerPage);
        const isFirstPage = i === 0; // Verifica se é a primeira página

        addTableToPDF(pageData, isFirstPage);

        startY = doc.autoTable.previous.finalY + 20; // Ajusta a posição Y para o rodapé

        // Se não for a última página e houver mais dados, adicione uma nova página
        if (i + maxLinesPerPage < filteredData.length) {
            doc.addPage();
            startY = 30; // Reinicia a posição Y para a nova página
            pageCount++;
        }
    }

    // Adicionar rótulo e valor do total pago na última página
    const totalPagoY = startY + 20;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text('Total Pago:', 20, totalPagoY); // Adiciona o rótulo

    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(
        parseFloat(this.total_pago_comissao || 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }),
        100, // Posição x do valor
        totalPagoY // Posição y do valor
    );

    // Adicionar data e hora no rodapé da última página
    const now = new Date();
    const formattedDate = now.toLocaleDateString('pt-BR');
    const formattedTime = now.toLocaleTimeString('pt-BR');
    const dateTimeString = `Data e Hora de Criação: ${formattedDate} ${formattedTime}`;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');

    // Data e Hora
    doc.text(dateTimeString, 20, doc.internal.pageSize.height - 30); // Ajusta a posição Y para a data e hora

    // Nome do Usuário
    doc.text('Usuário: ' + usuario, 20, doc.internal.pageSize.height - 15); // Ajusta a posição Y para o nome do usuário

    // Salvar o PDF
    doc.save(this.selectedStartDate + '_lista_comissao.pdf');
}

,



      
      filtrarTiposFunerarios(tipoFunerario) {
        const tiposPermitidos = "Todos";
        if (this.tipoFunerario === "Jazigo") {
           tiposPermitidos = ["CONCESSÃO DE COLUMBÁRIO", "CONCESSÃO DE JAZIGOS", "CONCESSÃO DE OSSÁRIO", "VENDA DE COLUMBÁRIO", "VENDA DE JAZIGOS - IMEDIATO", "VENDA DE JAZIGOS - PREVENTIVO", "VENDA DE JAZIGOS - TRANSITÓRIO"];
        }
        if (this.tipoFunerario === "Plano") {
           tiposPermitidos = ["COMPLETO",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS"];
        }

        if (this.tipoFunerario === "Cremação") {
           tiposPermitidos = [ "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "URNAS CINERARIAS"];
        }

        if (this.tipoFunerario === "Todos" ||  this.tipoFunerario ===  "Funerária" || this.tipoFunerario ===  "Serviços Administrativos e Cemiteriais" || this.tipoFunerario ===  "Serviços Cemiteriais") {
           tiposPermitidos = [ "Todos"];
        }

        if (tiposPermitidos.includes(tipoFunerario)) {
          return true; // Mantém apenas os tipos permitidos
        } else {
          return false; // Remove os tipos não permitidos
        }
      },
     
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },

        startAnimation(targetValue) {
            this.animationInterval = setInterval(() => {
              if (this.animatedValue < targetValue) {
                this.animatedValue += 1; // Atualiza o valor animado
              } else {
                clearInterval(this.animationInterval); // Interrompe a animação quando atingir o valor desejado
              }
            }, 10); // Intervalo em milissegundos entre cada atualização
          },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      carregarLoad(){

         this.bool_03 = true
         this.bool_03 = true;

      },

      async dashboard() {

              this.isLoading = true;

              this.selectMode = "Vencimento";
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              const formattedStartDate2 = converteDataParaFormatoISO(this.selectedStartDate2);
              const formattedEndDate2 = converteDataParaFormatoISO(this.selectedEndDate2);

              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 365) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        this.dias = 365;
                        return 
                      }
                   
                      Comissao.lista_comissao(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.lista_comissao = response.data;  
                            
                            
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.calcularDadosPorUsuario();
                            this.isLoading = false;

                          })  
       
                 
      },
   

      download_xls() {

          this.showProgress = true;              
          const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
          const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
          const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);


          if (diferencaEmDias > 60) {
            this.isLoading = false;
            this.showFilter = true;
            this.dias = 60;
            return 
          }


          Comissao.lista_comissao_xls(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto)
            .then(response => {
                  if (response.status === 200) {
                  
                          const fileName = `comissao_${Date.now()}.xlsx`;
                          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                          const fileLink = document.createElement('a')
                          fileLink.href = fileURL
                          fileLink.setAttribute('download', fileName)
                          document.body.appendChild(fileLink)
                          fileLink.click()
                      
                  }
                })
                .catch(error => {
                  // Lida com erros
                  console.error('Erro ao baixar o arquivo Excel:', error);
                }) .finally(() => {
                  // Após o término do download, oculte o diálogo de progresso
                  this.showProgress = false;
                });
        },

        download_alelo_xls() {

          this.showProgress = true;              
          const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
          const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
          const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);


          if (diferencaEmDias > 60) {
            this.isLoading = false;
            this.showFilter = true;
            this.dias = 60;
            return 
          }


          Comissao.lista_comissao_alelo_xls(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto)
            .then(response => {
                  if (response.status === 200) {
                  
                          const fileName = `comissao_${Date.now()}.xlsx`;
                          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                          const fileLink = document.createElement('a')
                          fileLink.href = fileURL
                          fileLink.setAttribute('download', fileName)
                          document.body.appendChild(fileLink)
                          fileLink.click()
                      
                  }
                })
                .catch(error => {
                  // Lida com erros
                  console.error('Erro ao baixar o arquivo Excel:', error);
                }) .finally(() => {
                  // Após o término do download, oculte o diálogo de progresso
                  this.showProgress = false;
                });
          },

        calcularDadosPorUsuario() {
            const dadosPorUsuario = {};
            this.lista_comissao_grupo = '';
            this.total_pago_comissao = 0;
            this.total_pago = 0;

            this.total_pago_pre = 0,
            this.total_pago_par = 0,
            this.total_pago_mem = 0,
            this.total_pago_ver = 0,
            this.total_pago_am = 0,

            this.total_resultado = 0;

            this.lista_comissao.forEach(item => {
              if (!dadosPorUsuario.hasOwnProperty(item.usuario)) {
                dadosPorUsuario[item.usuario] = {
                  comissao: 0,
                  empresa: '',
                  original: 0,
                  pago: 0,
                  resultado: 0,
                  comissaofinal: 0
                };
              }

              dadosPorUsuario[item.usuario].comissao += item.comissao;
              dadosPorUsuario[item.usuario].usuario = item.usuario;
              dadosPorUsuario[item.usuario].tipo = item.tipo;

              dadosPorUsuario[item.usuario].empresa = item.empresa;
              dadosPorUsuario[item.usuario].total = item.total;
              dadosPorUsuario[item.usuario].ref= item.ref;
              dadosPorUsuario[item.usuario].original += item.orginal;
              dadosPorUsuario[item.usuario].percentual = item.percentual;
              dadosPorUsuario[item.usuario].pago += item.pago;
              dadosPorUsuario[item.usuario].resultado += item.resultado;
              dadosPorUsuario[item.usuario].resultado += item.conformidade;

              const vl = this.calcularComissao(item.empresa, item.resultado, item.comissao, item.orginal,  item.pago);
              this.total_pago_comissao += vl;
              this.total_pago += item.pago;
              
              
              if(item.empresa == "PREVIDA"){
                this.total_pago_pre += item.pago;
              }
              if(item.empresa == "PARQUE DAS FLORES"){
                this.total_pago_par += item.pago;
              }
              if(item.empresa == "MEMORIAL PARQUE MACEIÓ"){
                this.total_pago_mem += item.pago;
              }
              if(item.empresa == "CEMITÉRIO PREVIDA"){
                this.total_pago_ver += item.pago;
              }
              if(item.empresa == "PARQUE DO AGRESTE"){
                this.total_pago_am += item.pago;
              }


              this.total_resultado += item.resultado;


              dadosPorUsuario[item.usuario].comissaofinal += vl;
            

              if((item.tipo == 'SUPERVISOR (A)' || item.tipo == 'GERÊNCIA') && item.empresa == "PARQUE DAS FLORES"){
                  const vl_extra = this.total_pago * 0.01;
                  dadosPorUsuario[item.usuario].comissaofinal = vl_extra;
                  dadosPorUsuario[item.usuario].comissao = vl_extra;
                  this.total_pago_comissao += vl_extra;
              }

              if((item.tipo == 'SUPERVISOR (A)' || item.tipo == 'GERÊNCIA') && item.empresa == "MEMORIAL PARQUE MACEIÓ"){
                  const vl_extra = this.total_pago * 0.01;
                  dadosPorUsuario[item.usuario].comissaofinal = vl_extra;
                  dadosPorUsuario[item.usuario].comissao = vl_extra;
                  this.total_pago_comissao += vl_extra;
              }


              if((item.tipo == 'SUPERVISOR (A)' || item.tipo == 'GERÊNCIA') && item.empresa == "PARQUE DO AGRESTE"){
                  const vl_extra = this.total_pago * 0.02;
                  dadosPorUsuario[item.usuario].comissaofinal = vl_extra;
                  dadosPorUsuario[item.usuario].comissao = vl_extra;

                  this.total_pago_comissao += vl_extra;
              }


              if((item.tipo == 'SUPERVISOR (A)' || item.tipo == 'GERÊNCIA') && item.empresa == "CEMITERIO PREVIDA"){
                  const vl_extra = this.total_pago * 0.01;
                  dadosPorUsuario[item.usuario].comissaofinal = vl_extra;
                  dadosPorUsuario[item.usuario].comissao = vl_extra;

                  this.total_pago_comissao += vl_extra;
              }

            });

            this.lista_comissao_grupo = Object.values(dadosPorUsuario); // Alteração aqui para referenciar a variável local

          },

      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },


    getStatus (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },

      calcularComissao: function(empresa, resultado, comissao, original, pago) {
        
        if (empresa === "PREVIDA") {
          if (resultado < 0) {
            // Se o resultado for negativo, retorna a diferença entre pago e original
            //return pago - original;
            return comissao;
          } else {
            // Se o resultado não for negativo, retorna a comissão
            return comissao;
          }
        } else {
          // Se a empresa for diferente de "Previda", retorna a comissão
          return comissao;
        }
      },


      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },

    },
  }


   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

 
  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }



</script>


<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.custom-gray-background {
  height: 90px;
}

.row {
  display: flex;
  flex-wrap: wrap;
   flex: 0 1 auto !important; 
  margin: -12px;
}


/* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
.fill-width {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.v-menu__content{
  box-shadow:none !important;
  top: 4px !important;
}
/* Centralize horizontalmente */
.justify-center {
  justify-content: center;
}
/*rgb(71 130 180)*/
.gray-row {
  background-color: rgb(71 130 180); /* ou qualquer outra cor cinza que você preferir */
}
.cor-row {
  background-color: rgb(231 244 255)   ;
  transition: none !important; /* Desativa a transição de cor */

}

.linha{

    font-size: 11px !important;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .no-hover-effect.v-data-table tbody tr:hover {
    color: #3055a4;
  }
.card-container {
  display: flex;
  justify-content: space-around; /* ou outro alinhamento que desejar */
}

</style>
